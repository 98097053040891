<template>
  <div class="addtobag-box" :class="{ hidden: !visible }">
    <div v-if="visible" class="flex justify-between py-2 container--small">
      <template v-for="(type, index) in detailsOrder">
        <div v-if="type == 'setting'" class="capitalize">
          <template v-if="Item">
            <h4>
              <template v-if="Item.HasCentreStone || Item.HasComponentsWithCentreStone">
                {{ $t('setting-selection', 'Setting Selection') }}
              </template>
              <template v-else>
                {{ $t('item-selection', 'Item Selection') }}
              </template>

              <span v-if="!$chargeVat">{{ $t('ex-vat', '(ex VAT)') }}</span>
            </h4>

            <h5>
              {{ Item.Name }},
              {{
                getMetalById(route.query.metal)
                  ? translate(getMetalById(route.query.metal).label, getMetalById(route.query.metal).value)
                  : ''
              }}
              <span v-if="itemIsDefault"> ({{ $t('default') }}) </span>
            </h5>

            <h4 class="price">
              <span v-if="OrderInfo.ItemPrice.SettingDiscountInfo" class="product-discount nowrap">{{
                OrderInfo.ItemPrice.SettingPrice.Price.WebPrice.WithoutVat
              }}</span>
              <span> {{ OrderInfo.ItemPrice.SettingPrice.FinalPrice.WebPrice.WithoutVat }}</span>
            </h4>
          </template>
          <template v-else-if="false">
            <h4>
              {{ $t('setting-selection', 'Setting Selection') }}
            </h4>

            <h5>
              <NuxtLink
                class="underline"
                :to="
                  getShopRouteByLegacyName({
                    name: 'category',
                    query: {
                      'start-diamond': true,
                      shape: diamondToDisplay[0].ShapeId,
                      diamond: diamondToDisplay.map((d) => d.Code).join(','),
                    },
                  })
                "
              >
                {{ $t('select-setting', 'Select the setting') }}
              </NuxtLink>
            </h5>

            <h4>─</h4>
          </template>
        </div>

        <div v-if="type == 'diamond'" class="capitalize">
          <h4>
            {{ $t('selected', 'Selected') }}
            {{ translate($stoneType(diamondToDisplay[0], diamondToDisplay.length)) }}
            <span v-if="!$chargeVat">{{ $t('ex-vat', '(ex VAT)') }}</span>
          </h4>

          <h5>
            <template v-for="diamond in diamondToDisplay" :key="diamond.Code">
              <template v-if="diamondToDisplayIsDefault">
                {{ $t('default') }}
                ({{ diamond.ShapeName }}, {{ diamond.Carat }},
              </template>
              <template v-else>
                {{ diamond.ShapeName }}
                <span :data-cy-bag-carat="diamond.Carat"> ({{ diamond.Carat }}, </span>
              </template>

              <template v-if="$isGemstone" class="_p mb-1">
                <span :data-cy-bag-clarity="diamond.GemClarityName">{{ diamond.GemClarityName }}</span> ,
                <span :data-cy-bag-color="diamond.GemColorName">{{ diamond.GemColorName }}</span> )
              </template>

              <template v-else class="_p mb-1">
                <span :data-cy-bag-color="diamond.Color">{{ diamond.Color }}</span> ,
                <span :data-cy-bag-clarity="diamond.Clarity">{{ diamond.Clarity }}</span> )
              </template>

              <br />
            </template>
          </h5>

          <h4 class="price" :data-cy-bag-price="getDiamondPrice()">
            <span v-if="DiamondPrice(0).SavingsPrice" class="product-discount">{{
              currency(parseInt(DiamondPrice(0).Price.NumericPrice.WithoutVat), null, 0)
            }}</span>
            <span> {{ DiamondPrice(0).FinalPrice.WebPrice.WithoutVat }}</span>

            <template v-if="diamondToDisplay.length === 2">
              ,
              <span v-if="DiamondPrice(1).SavingsPrice" class="product-discount">
                {{ currency(parseInt(DiamondPrice(1).Price.NumericPrice.WithoutVat), null, 0) }}
              </span>
              <span> {{ DiamondPrice(1).FinalPrice.WebPrice.WithoutVat }}</span>
            </template>
          </h4>
        </div>
      </template>

      <div class="capitalize">
        <h4>{{ $t('grand-total', 'Grand Total') }}</h4>

        <h5>
          <template v-if="displayVat">{{ $t('inc-vat', '(inc VAT)') }}</template>
          <template v-else-if="!$chargeVat">{{ $t('ex-vat', '(ex VAT)') }}</template>
        </h5>

        <h4 class="grand-total">
          <span v-if="OrderInfo && OrderInfo.ItemPrice.HasDiscount" class="product-discount">{{
            OrderInfo.ItemPrice.TotalPrice.Price.WebPrice.WithVat
          }}</span>
          <span
            v-else-if="diamondToDisplay.length && diamondToDisplay[0].DiamondPrice.HasDiscount"
            class="product-discount"
            >{{
              currency(
                diamondToDisplay.reduce(
                  (total, d) => total + d.DiamondPrice.DiamondsPrice[0].Price.NumericPrice.WithVat,
                  0,
                ),
                '',
              )
            }}</span
          >
          <span>
            <template v-if="OrderInfo">
              {{ OrderInfo.ItemPrice.TotalPrice.FinalPrice.WebPrice.WithVat }}
            </template>
            <template v-else>
              {{ $currency }}
              {{
                currency(
                  diamondToDisplay.reduce(
                    (total, d) => total + d.DiamondPrice.DiamondsPrice[0].FinalPrice.NumericPrice.WithVat,
                    0,
                  ),
                  '',
                )
              }}
            </template>
          </span>
        </h4>
      </div>

      <div class="flex flex-col justify-center">
        <Button77 small rounded block variant="outlined" class="mb-2" open-book-appointment="location">
          {{ $t('book-an-appointment', 'Book an appointment') }}
        </Button77>

        <Button77
          v-if="Item"
          small
          rounded
          block
          data-gtm-tracking="add-to-bag"
          data-cy="addToBasket"
          @click="addToShoppingBag"
        >
          {{ $t('add-to-basket', 'Add to basket') }}
        </Button77>

        <SetStone v-else :opened="diamondToDisplay" class="block" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useProductsStore } from '~/store/productsStore'
import { getMetalById } from '~/utils/shop/metals'
import SetStone from '~/components/shop/SetStone.vue'
import Button77 from '@/components/ui/Button77.vue'
import { useFilterStore } from '~/store/filterStore'
import { useLayoutStore } from '~/store/layoutStore'

const productsStore = useProductsStore()
const { getQuery } = useFilterStore()
const { getShopRouteByLegacyName } = useShopRoute()
const route = useRoute()

const { routeName } = useShopRouteName()
const { translate } = useTranslations()

const { category, itemIsDefault, selectedDiamonds, selectedDiamondsIsDefault, item } = storeToRefs(productsStore)
const { $isExpress, $chargeVat, $stoneType, $isGemstone, currency } = useShop()
const { isDesktop } = useMQ()

// computeds
const Item = computed(() => item.value.ItemSettings?.Item)
const OrderInfo = computed(() => item.value.ItemSettings?.OrderInfo)
const BandSettings = computed(() => item.value.BandSettings)
const diamondToDisplay = computed(() => {
  if (route.query.diamond) {
    const diamondArray = route.query.diamond?.split(',').sort() || []
    return selectedDiamonds.value.filter((d) => diamondArray.includes(d.Code))
  }

  if (Item.value && OrderInfo.value?.ItemPrice?.DiamondsPrice?.length) {
    return selectedDiamonds.value.filter((d) =>
      OrderInfo.value?.ItemPrice.DiamondsPrice.some((dp) => dp.UniqueCode == d.Code),
    )
  }

  return selectedDiamonds.value || []
})
const visible = computed(() => {
  const visible =
    isDesktop.value &&
    !$isExpress.value &&
    (routeName.value != 'category' || OrderInfo.value) &&
    routeName.value != 'item-finish' &&
    !!(Item.value || (diamondToDisplay.value || []).length) &&
    !(Item.value && Item.value.IsAvailableOnRequestOnly)
  // TODO: reproduce it and check if it still relevant
  // if (visible) {
  //     $('html').addClass('hasStickyFooter');
  //     if (!$('style.push-google-rating-up').length)
  //         $('head').append('<style class="push-google-rating-up" type="text/css">#___ratingbadge_0 { transform: translateY(-95px) }</style>');
  // } else {
  //     $('html').removeClass('hasStickyFooter');
  //     $('style.push-google-rating-up').remove();
  // }
  return visible
})

const displayVat = computed(() => {
  return $chargeVat && !isGermany() && !isSweden() && !isDenmark() && !isSwitzerland() && isEUCountry()
})
const StartDiamond = computed(() => {
  return (
    route.query['start-diamond'] ||
    routeName.value == 'diamond' ||
    routeName.value == 'diamond-details' ||
    (route.query.diamond && !route.query.item)
  )
})
const detailsOrder = computed(() => {
  if (!Item.value) {
    return ['diamond']
  } else if (!Item.value.HasCentreStone && !Item.value.HasComponentsWithCentreStone) {
    return ['setting']
  } else if (StartDiamond.value) {
    return ['diamond', 'setting']
  } else {
    return ['setting', 'diamond']
  }
})

// methods
const addToShoppingBag = async () => {
  const diamond =
    (Item.value.HasCentreStone || Item.value.HasComponentsWithCentreStone) && diamondToDisplay.value.length
      ? diamondToDisplay.value.map((d) => d.Code)
      : undefined

  if (route.query.quickShipping == 1) {
    ga_event_quick_init('quick x non quick', 'add to bag with quick setting & stone')
  } else {
    ga_event_quick_init('quick x non quick', 'add to bag without quick setting & stone')
  }

  await productsStore.addToShoppingBag({
    ...route.query,
    subcategory: Item.value.SubCategoryId,
    item: Item.value.Id,
    query: {
      ...route.query,
      ...getQuery(),
      diamond,
      sizeName: BandSettings.value && BandSettings.value.BandPreferences.RingSizeName,
      item: Item.value.Id,
      subcategory: Item.value.SubCategoryId,
    },
    quick: true,
  })
}

const getDiamondPrice = () => {
  if (diamondToDisplay.value[0] && diamondToDisplay.value[0].DiamondPrice) {
    return parseInt(diamondToDisplay.value[0].DiamondPrice.TotalDiamondPrice.Price.NumericPrice.WithoutVat)
  }
}

const DiamondPrice = (index) => {
  if (OrderInfo.value) {
    return OrderInfo.value.ItemPrice.DiamondsPrice[index]
  } else {
    return diamondToDisplay.value?.[index]?.DiamondPrice.DiamondsPrice[0]
  }
}

const { showExpressShoppingBar } = storeToRefs(useLayoutStore())

watch(
  () => visible.value,
  (value) => {
    if (value) {
      showExpressShoppingBar.value = true
    }
  },
)
onBeforeUnmount(() => {
  showExpressShoppingBar.value = false
})
</script>

<style lang="scss" scoped>
.addtobag-box {
  //position: fixed;
  z-index: 100;
  //bottom: 0;
  width: 100%;
  border-top: 1px solid #000000;
  background-color: #f5f5f5;
  height: 94px;

  // body:not(.defered) & {
  //   display: none;
  // }

  @media (max-width: 767px) {
    display: none;
  }

  &.collapse-hidden {
    border-top-color: transparent;
  }

  h4,
  h5 {
    font-size: 12px;
    letter-spacing: 0.3px;
    line-height: 18px;
    margin-bottom: 0;
  }

  h4 {
    font-family: 'Nunito Sans Bold';

    &.price {
    }
  }

  .product-discount {
    font-family: 'Nunito Sans Light';
    text-decoration: line-through;
    white-space: nowrap;

    + span {
      color: #9e0606;
    }
  }

  h5 {
    font-family: 'Nunito Sans Light';
    height: 31px;
    line-height: 1.3;
    margin-top: 2px;
  }

  .grand-total {
    position: relative;
    font-size: 16px;
    line-height: 16px;

    .product-discount {
      position: absolute;
      right: 100%;
      margin-right: 5px;
      font-size: 12px;
    }
  }

  ._cta-black,
  ._cta-white {
    // width: 100%;
    min-height: 32px;
    // display: block;
    border-radius: 4px;
  }
}
</style>
